// For QA
export default {
    apiURL : "https://api.cosmos.id/api/QA/Index",
    urlSAPCAS : "http://scq/sap/cas_new/",
    urlSAP : "http://scq/sap/json/",
    urlASC : "https://wsq.cosmos.id/services/asc.asmx"
}

// For PRO
// export default {
//     apiURL : "https://api.cosmos.id/api/PRO/Index",
//     urlSAPCAS : "http://scp/sap/cas_new/",
//     urlSAP : "http://scp/sap/json/",
//     urlASC : "https://ws.cosmos.id/services/asc.asmx"
// }